<template>
  <div>
    <n-modal
      v-model:show="modal.showModal"
      :mask-closable="false"
      :closable="false"
      preset="dialog"
      :title="t('profile.verification.title')"
    >
      <verify @verification-success="handleSuccess" />
    </n-modal>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { NModal } from "naive-ui";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { sleep } from "../../shared/utils";
import Verify from "./Verify.vue";
export default {
  components: { NModal, Verify },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const modal = ref({
      showModal: false,
    });
    const store = useStore();

    const handleSuccess = () => (modal.value.showModal = false);

    onMounted(async () => {
      while (!store.getters["user/isUserLoaded"]) {
        await sleep(500);
      }
      if (!store.getters["user/verified"]) {
        modal.value.showModal = true;
      }
    });
    return {
      t,
      modal,
      handleSuccess,
    };
  },
};
</script>

<template>
  <div>
    <div style="text-align: end">
      <n-form-item path="haveDelivery" v-show="location !== null && location !== ''">
        {{ t("publications.createUpdate.steps.three.form.offerDelivery.label") }}
        <n-switch v-model:value="haveDelivery"> </n-switch>
      </n-form-item>
    </div>

    <n-form-item
      :label="t('publications.createUpdate.steps.three.form.location.label')"
      path="locationInfo"
    >
      <location-finder v-model="locationInfo" showAvailabilityRadio />
    </n-form-item>
  </div>
</template>
<script>
import { ref, toRef, watch } from "vue";
import { NFormItem, NSwitch } from "naive-ui";
import LocationFinder from "@/components/LocationFinder.vue";
import { useI18n } from "vue-i18n";

export default {
  components: { NFormItem, NSwitch, LocationFinder },
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const modelRef = ref(props.modelValue);
    const location = toRef(modelRef.value, "location");
    const haveDelivery = toRef(modelRef.value, "haveDelivery");
    const locationInfo = toRef(modelRef.value, "locationInfo");

    watch([location, haveDelivery, locationInfo], () => {
      emit("update:modelValue", modelRef.value);
    });

    return {
      t,
      location,
      haveDelivery,
      locationInfo,
    };
  },
};
</script>

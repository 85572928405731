<template>
  <n-spin :show="isLoading">
    <check-verified-profile />
    <n-space vertical :class="!isMobile ? 'stepper' : ''">
      <n-alert type="warning" v-if="model.state === 'ACTIVE'" class="mb-4">
        {{ t("publications.createUpdate.warningOnTop") }}
      </n-alert>
      <n-form :model="model" ref="form" :rules="rules">
        <n-steps vertical :current="current" :status="currentStatus" class="ml-2">
          <n-step :title="t('publications.createUpdate.steps.one.title')" class="mr-4">
            <div class="n-step-description pt-4" v-if="current === 1">
              <title-section v-model="model" /><!--check-verified-profile /-->
              <navigations-button-section
                :current="current"
                :step="1"
                isInitStep
                :totalSteps="totalSteps"
                @clickNext="next"
              />
            </div>
          </n-step>
          <n-step :title="t('publications.createUpdate.steps.two.title')" class="mr-4">
            <div class="n-step-description pt-4" v-if="current === 2">
              <upload-section v-model="model" />
              <navigations-button-section
                :current="current"
                :step="2"
                :totalSteps="totalSteps"
                @clickNext="next"
                @clickBack="prev"
              />
            </div>
          </n-step>
          <n-step :title="t('publications.createUpdate.steps.three.title')" class="mr-4">
            <div class="n-step-description pt-4" v-if="current === 3">
              <location-section v-model="model" />
              <navigations-button-section
                :current="current"
                :step="3"
                :totalSteps="totalSteps"
                @clickNext="next"
                @clickBack="prev"
              />
            </div>
          </n-step>
          <n-step :title="t('publications.createUpdate.steps.four.title')" class="mr-4">
            <div class="n-step-description" v-if="current === 4">
              <prices-section v-model="model" />
              <navigations-button-section
                :current="current"
                :step="4"
                :totalSteps="totalSteps"
                @clickNext="next"
                @clickBack="prev"
              />
            </div>
          </n-step>
          <n-step :title="t('publications.createUpdate.steps.five.title')" class="mr-4">
            <div class="n-step-description pt-4" v-if="current === 5">
              <checklists-section v-model="model" />
              <navigations-button-section
                :current="current"
                :step="5"
                :totalSteps="totalSteps"
                @clickNext="next"
                @clickBack="prev"
              />
            </div>
          </n-step>
          <n-step :title="t('publications.createUpdate.steps.six.title')" class="mr-4">
            <div v-if="current === 6">
              <view-publication
                :publicationId="model.id"
                :publication="model"
                isPreview
              />
              <br />
              <navigations-button-section
                :current="current"
                :step="6"
                :totalSteps="totalSteps"
                @clickNext="next"
                @clickBack="prev"
                isFinalStep
              />
            </div>
          </n-step>
        </n-steps>
      </n-form>
    </n-space>
  </n-spin>
</template>

<script>
import TitleSection from "@/components/publication/maintainers/Title.vue";
import UploadSection from "@/components/publication/maintainers/Uploads.vue";
import LocationSection from "@/components/publication/maintainers/Location.vue";
import PricesSection from "@/components/publication/maintainers/Prices.vue";
import ChecklistsSection from "@/components/publication/maintainers/Checklists.vue";
import NavigationsButtonSection from "@/components/publication/maintainers/NavigationsButton.vue";
import CheckVerifiedProfile from "@/components/myProfile/CheckVerifiedProfile.vue";
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import responsive from "@/mixins/responsive";
import { useMessage, NAlert, NSpin, NSpace, NForm, NSteps, NStep } from "naive-ui";
import ViewPublication from "@/views/ViewPublication.vue";
import { useRouter } from "vue-router";

import { floatToCurrency } from "@/shared/utils";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CreateUpdatePublication",
  mixins: [responsive],
  props: {
    publicationId: String,
  },
  components: {
    TitleSection,
    UploadSection,
    LocationSection,
    PricesSection,
    ChecklistsSection,
    NavigationsButtonSection,
    ViewPublication,
    NAlert,
    NSpin,
    NSpace,
    NForm,
    NSteps,
    NStep,
    CheckVerifiedProfile,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const message = useMessage();
    const isLoadingRef = ref(false);
    const currentRef = ref(1);
    const totalStepsRef = ref(6);
    const currentStatusRef = ref("process");
    const store = useStore();
    const router = useRouter();
    const formRef = ref(null);
    const modelRef = ref({
      locationInfo: { address: null, availabilityRatio: 5, coordinates: null },
      user: store.getters["auth/user_id"],
      title: null,
      description: null,
      state: "DRAFT",
      haveDelivery: false,
      prices: {
        pricePerDay: null,
        weeklyDiscount: null,
        monthlyDiscount: null,
        discountedPricePerDayForWeeks: null,
        discountedPricePerDayForMonths: null,
        offerDiscount: false,
      },
      depositRequired: null,
      checkListFields: [],
      categoryID: null,
      orderPhotos: [],
    });

    const createPublication = (publication) =>
      store.dispatch("maintainer_publication/createPublication", publication);

    const updatePublication = (publication) =>
      store.dispatch("maintainer_publication/updatePublication", publication);

    onMounted(() => {
      isLoadingRef.value = true;
      store.dispatch("app/hideSearchBar");
      if (props.publicationId !== undefined && props.publicationId !== "") {
        store
          .dispatch(
            "maintainer_publication/getPublicationByIdToUpdate",
            props.publicationId
          )
          .then((selectedPublication) => {
            modelRef.value = (({
              id,
              user,
              title,
              description,
              state,
              prices,
              depositRequired,
              haveDelivery,
              checkListFields,
              categoryID,
              orderPhotos,
              locationInfo,
              _version,
            }) => ({
              id,
              user,
              title,
              description,
              state,
              prices,
              depositRequired,
              haveDelivery,
              checkListFields,
              categoryID,
              orderPhotos,
              locationInfo,
              _version,
            }))(selectedPublication);
          });
      }
      store.dispatch("maintainer_category/fillCategories").finally(() => {
        isLoadingRef.value = false;
      });
    });

    onUnmounted(() => {
      store.dispatch("app/showSearchBar");
    });

    const saveProcess = async () => {
      if (modelRef.value.checkListFields?.length > 0) {
        for (let index = 0; index < modelRef.value.checkListFields.length; index++) {
          modelRef.value.checkListFields[index].id = index + 1;
        }
      }
      if (modelRef.value.id == null) {
        createPublication(modelRef.value)
          .then((publication) => {
            modelRef.value.id = publication.id;
            modelRef.value._version = publication._version;
            message.success(
              t("commons.actions.created", {
                reference: t("commons.labels.publication", 1),
              })
            );
          })
          .catch((err) => {
            console.error(err);
            message.error(
              t("commons.errors.creating", {
                reference: t("commons.labels.publication", 1),
              })
            );
            throw err;
          });
      } else {
        updatePublication(modelRef.value)
          .then((publication) => {
            modelRef.value._version = publication._version;
          })
          .catch((err) => {
            console.error(err);
            message.error(
              t("commons.errors.updating", {
                reference: t("commons.labels.publication", 1),
              })
            );
            throw err;
          });
      }
    };

    return {
      t,
      current: currentRef,
      currentStatus: currentStatusRef,
      form: formRef,
      model: modelRef,
      isLoading: isLoadingRef,
      floatToCurrency,
      next() {
        formRef.value.validate((errors) => {
          if (!errors) {
            isLoadingRef.value = true;
            if (currentRef.value == totalStepsRef.value) {
              modelRef.value.state = "ACTIVE";
            }
            saveProcess()
              .then(() => {
                if (currentRef.value == totalStepsRef.value) {
                  store.dispatch("notifications/FireNotification", {
                    userId: modelRef.value.user,
                    fromUserId: modelRef.value.user,
                    title: t("publications.dialogs.publicationReady.title"),
                    message: t("publications.dialogs.publicationReady.content", {
                      publicationTitle: modelRef.value.title,
                    }),
                    avatar: {
                      publicationID: modelRef.value.id,
                      publicationUser: modelRef.value.user,
                      defaultImage: modelRef.value.orderPhotos[0],
                    },
                    targetRoute: {
                      name: "view-publication",
                      params: [
                        {
                          key: "publicationId",
                          value: modelRef.value.id,
                        },
                      ],
                    },
                  });
                  message.success(
                    t("commons.actions.saved", {
                      reference: t("commons.labels.publication", 1),
                    })
                  );
                  router.push({ name: "list-publication" });
                } else {
                  if (
                    currentRef.value === null ||
                    currentRef.value >= totalStepsRef.value
                  ) {
                    currentRef.value = 1;
                  } else {
                    currentRef.value++;
                  }
                }
              })
              .finally(() => {
                isLoadingRef.value = false;
              });
          } else {
            console.error(errors);
          }
        });
      },
      prev() {
        if (currentRef.value === 1) currentRef.value = totalStepsRef.value;
        else currentRef.value--;
      },
      rules: {
        title: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input", "blur"],
        },
        description: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input", "blur"],
        },
        locationInfo: {
          type: "object",
          required: true,
          fields: {
            address: {
              required: true,
              type: "string",
              message: t("commons.form.validation.required"),
              trigger: ["input", "blur"],
            },
            availabilityRatio: {
              required: true,
              type: "number",
              message: t("commons.form.validation.required"),
              trigger: ["input", "blur"],
            },
            coordinates: {
              type: "object",
              required: true,
              message: t("commons.form.validation.required"),
              fields: {
                lat: {
                  required: true,
                  type: "number",
                  message: t("commons.form.validation.required"),
                },
                long: {
                  required: true,
                  type: "number",
                },
              },
            },
            postalCode: {
              required: true,
              type: "string",
              trigger: ["input", "blur"],
              message: t("commons.form.validation.required"),
            },
          },
        },
        depositRequired: {
          type: "number",
          validator(rule, value) {
            return value > 0;
          },
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input", "blur"],
        },
        prices: {
          pricePerDay: {
            required: true,
            type: "number",
            validator(rule, value) {
              return value > 0;
            },
            message: t("commons.form.validation.required"),
            trigger: ["input", "blur"],
          },
          weeklyDiscount: {
            required: true,
            type: "number",
            validator(rule, value) {
              if (!value) {
                return modelRef.value.prices.monthlyDiscount != null;
              }
              return value > 1 && value < 99;
            },
            message: t("commons.form.validation.valueOutOfRange"),
            trigger: ["input", "blur"],
          },
          monthlyDiscount: {
            required: true,
            type: "number",
            validator(rule, value) {
              if (!value && !modelRef.value.prices.weeklyDiscount) {
                return new Error(t("commons.form.validation.required"));
              }
              if (
                modelRef.value.prices.weeklyDiscount &&
                value === modelRef.value.prices.weeklyDiscount
              ) {
                return new Error(t("commons.form.validation.required"));
              }
              if (
                modelRef.value.prices.weeklyDiscount &&
                value < modelRef.value.prices.weeklyDiscount
              ) {
                return new Error(t("commons.form.validation.required"));
              }
              if (value < 1 || value > 99) {
                return new Error(t("commons.form.validation.valueOutOfRange"));
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        },
        state: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["change", "blur"],
        },
        categoryID: {
          required: true,
          trigger: ["blur", "change"],
          message: t("commons.form.validation.required"),
        },
        orderPhotos: {
          required: true,
          trigger: ["change"],
          type: "array",
          min: 1,
          message: t("commons.form.validation.required"),
        },
      },
      handleUpdateValue(value) {
        store.dispatch("maintainer_category/getCategoryById", value).then((result) => {
          if (result.checkListBaseFields?.length > 0) {
            modelRef.value.checkListFields = result.checkListBaseFields;
          }
        });
      },
      showDiscountPanel: ref(false),
      totalSteps: totalStepsRef,
    };
  },
});
</script>

<style scoped>
.n-step-description {
  margin: 0 auto;
  max-width: 600px;
}

.stepper {
  margin: 0 auto;
  max-width: 79.5%;
}

.n-step-description :deep(.n-form-item-label__asterisk) {
  visibility: hidden;
}

.n-step-description :deep(.n-dynamic-input .n-dynamic-input-item) {
  margin-bottom: unset;
}

.custom-radio-group {
  text-align: -webkit-left;
}
.custom-radio-group .n-radio {
  padding-top: 5px;
}
:deep(.n-form-item .n-form-item-blank) {
  display: unset;
}

:deep(.n-form-item) {
  display: unset;
}
:deep(.n-table) {
  text-align: unset;
}

:deep(.n-radio-group) {
  display: unset;
}

:deep(.n-table td) {
  border-right: unset;
  border-bottom: unset;
}

:deep(.n-input-group-label) {
  width: 113px;
  font-size: 12px;
}
</style>

<template>
  <div>
    <n-dynamic-input
      v-model:value="checkListFields"
      :on-create="onNewCheckList"
      #="{ index, value }"
    >
      <n-input-group :class="!isMobile ? 'desktop-kelper' : ''">
        <n-space :vertical="isMobile"
          ><n-form-item
            ignore-path-change
            :path="`checkListFields[${index}].name`"
            style="min-width: 100%"
          >
            <n-select
              :consistent-menu-width="false"
              :options="optionsType"
              v-model:value="checkListFields[index].type"
              :style="!isMobile ? 'width: 150px' : ''"
            /> </n-form-item
          ><n-form-item
            ignore-path-change
            :path="`checkListFields[${index}].name`"
            :rule="dynamicRule"
            style="width: 100%"
          >
            <n-input
              placeholder="Name"
              @keydown.enter.prevent
              v-model:value="checkListFields[index].name"
              :style="!isMobile ? 'width: 200px' : ''"
            /> </n-form-item
        ></n-space>
      </n-input-group>
    </n-dynamic-input>
    <br v-if="checkListFields.length <= 0" />
  </div>
</template>
<style lang="scss" scoped>
.desktop-kelper {
  width: unset;
  padding-left: 20%;
}
:deep(.n-dynamic-input-item__action) {
  @media screen and (max-width: 799px) {
    padding-top: 65px;
  }
}
</style>
<script>
import { NDynamicInput, NInputGroup, NFormItem, NSelect, NInput, NSpace } from "naive-ui";
import { useStore } from "vuex";
import { ref, toRef, watch, computed } from "vue";
import responsive from "@/mixins/responsive";
import { useI18n } from "vue-i18n";

export default {
  components: {
    NDynamicInput,
    NInputGroup,
    NFormItem,
    NSelect,
    NInput,
    NSpace,
  },
  props: {
    modelValue: Object,
  },
  mixins: [responsive],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const modelRef = ref(props.modelValue);
    const checkListFieldsRef = toRef(modelRef.value, "checkListFields");

    watch(checkListFieldsRef, () => {
      emit("update:modelValue", modelRef.value);
    });

    const onNewCheckList = () => {
      return {
        id: 0,
        name: "",
        type: "STRING",
      };
    };

    const optionsType = computed(() => store.getters["shared/options_inputType"]);

    const dynamicRule = {
      required: true,
      trigger: ["blur", "change"],
      message: t("commons.form.validation.required"),
    };

    return {
      t,
      checkListFields: checkListFieldsRef,
      onNewCheckList,
      optionsType,
      dynamicRule,
    };
  },
};
</script>

<template>
  <div>
    <n-button
      class="mr-2"
      v-if="current === step && !isInitStep && !hideBackButton"
      size="small"
      type="default"
      @click="back"
      :loading="loading"
    >
      {{ t("commons.actions.back") }}
    </n-button>
    <n-button
      class="ml-2"
      v-if="current === step"
      :type="type"
      size="small"
      @click="next"
      :loading="loading"
      :disabled="!canContinue"
    >
      <span v-if="isFinalStep">{{ t("commons.actions.finish") }}</span
      ><span v-else>{{ nextButtonText || t("commons.actions.next") }}</span>
    </n-button>
  </div>
</template>
<script>
import { NButton } from "naive-ui";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

export default {
  components: { NButton },
  props: {
    current: Number,
    step: Number,
    isInitStep: Boolean,
    isFinalStep: Boolean,
    totalSteps: Number,
    loading: { type: Boolean, default: false },
    canContinue: { type: Boolean, default: true },
    hideBackButton: { type: Boolean, default: false },
    nextButtonText: { type: String, default: null },
    nextButtonType: { type: String, default: null },
  },
  emits: ["clickNext", "clickBack"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const next = () => emit("clickNext");
    const back = () => emit("clickBack");

    return {
      t,
      next,
      back,
      type: computed(() =>
        props.nextButtonType
          ? props.nextButtonType
          : props.current == props.totalSteps
          ? "primary"
          : "default"
      ),
    };
  },
};
</script>

<template>
  <div>
    <n-divider>{{ t("publications.createUpdate.steps.four.form.rent.title") }}</n-divider>
    <n-form-item
      :path="`prices.pricePerDay`"
      :label="t('publications.createUpdate.steps.four.form.rent.price.label')"
      ><n-input-group>
        <n-input-number
          :precision="currencyPrecision"
          :show-button="false"
          placeholder="0"
          :style="{ width: '100%' }"
          v-model:value="prices.pricePerDay"
        >
          <template #prefix>{{ t("commons.financial.currencySymbol") }}</template>
        </n-input-number>
        <n-input-group-label :style="{ width: '113px' }">
          {{ t("publications.createUpdate.steps.four.form.rent.price.suffix") }}
        </n-input-group-label>
      </n-input-group>
    </n-form-item>
    <div style="text-align: end" v-if="prices.pricePerDay > 0">
      {{ t("publications.createUpdate.steps.four.form.rent.offerDiscount.label") }}
      <n-switch v-model:value="prices.offerDiscount"> </n-switch>
    </div>
    <div style="text-align: end" v-if="prices.pricePerDay > 0">
      {{ t("publications.createUpdate.steps.four.form.rent.offerCancelationFree.label") }}
      <n-switch v-model:value="prices.offerCancelationFree"> </n-switch>
    </div>
    <div v-if="prices.pricePerDay > 0 && prices.offerDiscount">
      <n-divider>{{
        t("publications.createUpdate.steps.four.form.discounts.title")
      }}</n-divider>
      <n-card
        :title="t('publications.createUpdate.steps.four.form.discounts.weekly.title')"
      >
        <n-grid x-gap="12" :cols="3">
          <n-gi
            ><n-form-item :path="`prices.weeklyDiscount`"
              ><n-input-group>
                <n-input-number
                  :precision="0"
                  :show-button="false"
                  placeholder="0"
                  :style="{ width: '100%' }"
                  :min="1"
                  :max="99"
                  v-model:value="prices.weeklyDiscount"
                >
                  <template #suffix>%</template>
                </n-input-number>
              </n-input-group>
            </n-form-item></n-gi
          ><n-gi :span="2">
            <div v-if="prices.weeklyDiscount > 0" class="pt-2">
              <n-text delete>{{ floatToCurrency(prices.pricePerDay) }}</n-text>
              {{
                floatToCurrency(
                  prices.discountedPricePerDayForWeeks,
                  t("commons.labels.day", 1)
                )
              }}
            </div>
          </n-gi></n-grid
        >
      </n-card>
      <br />
      <n-card
        :title="t('publications.createUpdate.steps.four.form.discounts.monthly.title')"
      >
        <n-grid x-gap="12" :cols="3">
          <n-gi
            ><n-form-item :path="`prices.monthlyDiscount`"
              ><n-input-group>
                <n-input-number
                  :precision="0"
                  :show-button="false"
                  placeholder="0"
                  :style="{ width: '100%' }"
                  :min="1"
                  :max="99"
                  v-model:value="prices.monthlyDiscount"
                >
                  <template #suffix>%</template>
                </n-input-number>
              </n-input-group>
            </n-form-item></n-gi
          ><n-gi :span="2">
            <div v-if="prices.monthlyDiscount > 0" class="pt-2">
              <n-text delete>{{ floatToCurrency(prices.pricePerDay) }}</n-text>
              {{
                floatToCurrency(
                  prices.discountedPricePerDayForMonths,
                  t("commons.labels.day", 1)
                )
              }}
            </div>
          </n-gi></n-grid
        >
      </n-card>
    </div>
    <div v-if="prices.offerCancelationFree">
      <n-divider>{{
        t("publications.createUpdate.steps.four.form.cancelation.title")
      }}</n-divider>
      <n-form-item
        :label="
          t('publications.createUpdate.steps.four.form.cancelation.daysBeforeRent.label')
        "
        path="limitDaysForCancelationFree"
      >
        <n-input-number
          :precision="0"
          :show-button="false"
          placeholder="0"
          v-model:value="prices.limitDaysForCancelationFree"
        >
          <template #suffix>{{
            t("commons.labels.day", prices.limitDaysForCancelationFree)
          }}</template>
        </n-input-number>
      </n-form-item>
    </div>
    <n-divider>{{ t("publications.createUpdate.steps.four.form.tool.title") }}</n-divider>
    <n-form-item
      :label="t('publications.createUpdate.steps.four.form.tool.depositAmount.label')"
      path="depositRequired"
    >
      <n-input-number
        :precision="currencyPrecision"
        :show-button="false"
        placeholder="0"
        v-model:value="depositRequired"
      >
        <template #suffix>{{ t("commons.financial.currencySymbol") }}</template>
      </n-input-number>
    </n-form-item>

    <n-alert
      :title="` ${
        currentServiceFee && currentServiceFee > 0
          ? t('publications.createUpdate.steps.four.form.helpSection.titleWithFeeMessage')
          : t('publications.createUpdate.steps.four.form.helpSection.title')
      }`"
      type="info"
      class="mb-4"
    >
      <ul>
        <li>
          {{
            t(
              "publications.createUpdate.steps.four.form.helpSection.lines.depositMessage"
            )
          }}
        </li>
        <li v-if="currentServiceFee && currentServiceFee > 0">
          {{
            t("publications.createUpdate.steps.four.form.helpSection.lines.feeMessage", {
              fee: currentServiceFee,
            })
          }}
        </li>
      </ul>
    </n-alert>
  </div>
</template>

<script>
import { defineComponent, computed, ref, toRef, watch } from "vue";
import { floatToCurrency } from "@/shared/utils";
import { useStore } from "vuex";
import {
  NDivider,
  NFormItem,
  NInputGroup,
  NInputNumber,
  NInputGroupLabel,
  NSwitch,
  NCard,
  NGrid,
  NGi,
  NText,
  NAlert,
} from "naive-ui";
import * as Big from "js-big-decimal";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "Prices",
  components: {
    NDivider,
    NFormItem,
    NInputGroup,
    NInputNumber,
    NInputGroupLabel,
    NSwitch,
    NCard,
    NGrid,
    NGi,
    NText,
    NAlert,
  },
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const modelRef = ref(props.modelValue);
    const pricesRef = toRef(modelRef.value, "prices");
    const depositRequiredRef = toRef(modelRef.value, "depositRequired");
    const calculateDiscount = (discount, price) => {
      discount = new Big(discount);
      price = new Big(price);

      return discount.compareTo(new Big(0)) > 0
        ? parseFloat(
            price
              .subtract(price.multiply(discount).divide(new Big(100)))
              .round(currencyPrecision.value)
              .getValue()
          )
        : parseFloat(price.round(currencyPrecision.value).getValue());
    };

    pricesRef.value.discountedPricePerDayForWeeks = computed(() =>
      calculateDiscount(pricesRef.value.weeklyDiscount, pricesRef.value.pricePerDay)
    );

    pricesRef.value.discountedPricePerDayForMonths = computed(() =>
      calculateDiscount(pricesRef.value.monthlyDiscount, pricesRef.value.pricePerDay)
    );

    watch(pricesRef, () => {
      emit("update:modelValue", modelRef.value);
    });

    const currentServiceFee = computed(
      () => store.state.app.configuration.SERVICE_FEE_OWNER
    );

    const currencyPrecision = computed(
      () => store.state.app.configuration.CURRENCY_PRECISION
    );

    return {
      t,
      prices: pricesRef,
      depositRequired: depositRequiredRef,
      floatToCurrency,
      currentServiceFee,
      currencyPrecision,
    };
  },
});
</script>

<template>
  <div>
    <n-form-item
      :label="t('publications.createUpdate.steps.one.form.title.label')"
      path="title"
    >
      <n-input
        v-model:value="model.title"
        type="text"
        :placeholder="t('publications.createUpdate.steps.one.form.title.placeholder')"
      />
    </n-form-item>
    <n-form-item
      :label="t('publications.createUpdate.steps.one.form.category.label')"
      path="categoryID"
    >
      <category-selector v-model:value="model.categoryID" />
    </n-form-item>
    <n-form-item
      :label="t('publications.createUpdate.steps.one.form.description.label')"
      path="description"
    >
      <n-input
        v-model:value="model.description"
        type="textarea"
        :placeholder="
          t('publications.createUpdate.steps.one.form.description.placeholder')
        "
      />
    </n-form-item>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { NFormItem, NInput } from "naive-ui";
import CategorySelector from "@/components/publication/CategorySelector.vue";
import { useI18n } from "vue-i18n";

export default {
  components: { NFormItem, NInput, CategorySelector },
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const modelRef = ref(props.modelValue);

    watch(modelRef, (newModelRef) => {
      emit("update:modelValue", newModelRef);
    });

    watch(props, (props) => (modelRef.value = props.modelValue));

    return { t, model: modelRef };
  },
};
</script>

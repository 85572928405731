<template>
  <n-space justify="space-around">
    <template v-if="!model.progress">
      <n-text v-if="verified">
        {{ t("profile.verification.verified") }}
      </n-text>
      <n-text v-else>
        {{ t("profile.verification.unverified") }}
      </n-text>
      <n-space justify="space-between" class="mt-3">
        <n-button type="error" size="large" @click="declineHandler">
          {{ t("commons.actions.decline") }}
        </n-button>
        <n-button type="success" size="large" @click="acceptHandler">
          {{ t("commons.actions.accept") }}
        </n-button>
      </n-space>
    </template>
    <template v-else>
      <n-progress type="circle" :percentage="100" v-if="model.processing">
        {{ t("profile.verification.verifying") }}
      </n-progress>
      <n-progress
        type="circle"
        :percentage="
          parseInt(numberAnimationInstRef?.formattedValue.integer ?? 0)
        "
        v-else
      >
        <n-number-animation
          ref="numberAnimationInstRef"
          :from="0"
          :to="100"
          :active="false"
          :duration="5000"
          @finish="checkHandler"
        />%
      </n-progress>
    </template>
  </n-space>
</template>
<script>
import {
  NButton,
  NText,
  NSpace,
  useMessage,
  NNumberAnimation,
  NProgress,
  useDialog,
} from "naive-ui";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { loadStripe } from "@stripe/stripe-js";
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
export default {
  components: {
    NButton,
    NText,
    NSpace,
    NNumberAnimation,
    NProgress,
  },
  emits: ["verification-success"],
  setup(_props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const dialog = useDialog();
    const store = useStore();
    const router = useRouter();
    const message = useMessage();
    const userId = computed(() => store.getters["auth/user_id"]);
    const verified = computed(() => store.getters["user/verified"]);
    const model = ref({
      progress: false,
      processing: false,
    });
    const numberAnimationInstRef = ref(null);

    const acceptHandler = async () => {
      await store.dispatch("app/lockUI");
      model.value.progress = true;
      const session = await store.dispatch("verifyId/getSession", userId.value);
      const stripe = await loadStripe(
        "pk_test_51LkBc2BclygdwDswhgN6b570XiM03EaUNj6UyTnycNIuEcrWgQIGm2qrEQjNEsanOT5M1FaOD8mcfjFeflnppIa500glNjR4fX"
      );
      const { error } = await stripe.verifyIdentity(session);
      await store.dispatch("app/unlockUI");
      if (error) {
        console.log("[error]", error);
        message.error("Ha ocurrido un error en la verificacion");
      } else {
        model.value.progress = true;
        setTimeout(() => numberAnimationInstRef.value?.play(), 1000);
      }
    };

    const declineHandler = async () => {
      router.push({ name: "Home" });
    };

    const checkHandler = async () => {
      model.value.processing = true;
      await store.dispatch("user/getUserById", userId.value);
      if (verified.value) {
        dialog.success({
          title: t("commons.labels.success"),
          content: t("profile.verification.result.success"),
          closable: false,
          maskClosable: false,
          positiveText: t("commons.actions.continue"),
          onPositiveClick: () => emit("verification-success"),
        });
      } else {
        model.value.progress = false;
        dialog.warning({
          title: t("profile.verification.result.warning"),
          closable: true,
          maskClosable: true,
          onClose: () => router.push({ name: "Home" }),
        });
      }
    };

    return {
      t,
      model,
      verified,
      declineHandler,
      acceptHandler,
      checkHandler,
      numberAnimationInstRef,
    };
  },
};
</script>

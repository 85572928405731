<template>
  <n-grid cols="1 s:4" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:2" offset="0 s:1">
      <div>
        <n-carousel show-arrow>
          <img
            v-for="image in listFiles"
            :key="image.name"
            :src="image.url"
            :alt="image.name"
            class="carousel-img"
          />
        </n-carousel>
      </div>
    </n-gi>
    <n-gi span="1 s:1">
      <div style="height: 100%">
        <publication-resumen
          v-if="model.id"
          :publication="model"
          showLocation
          showDeliveryInfo
          showPrice
          vertical
          showReviewInfo
        />
        <n-button
          v-if="!isPreview && !isMobile"
          type="primary"
          size="large"
          class="my-3"
          @click="isMyPublication ? onBack() : goToRent()"
        >
          {{ isMyPublication ? t("commons.actions.back") : t("commons.actions.rent") }}
        </n-button>
        <span class="my-3">
          <about-user v-if="model.id" :userId="model.user" userType="Owner" />
        </span>
      </div>
    </n-gi>
  </n-grid>
  <n-grid cols="1 s:4" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:2" offset="0 s:1">
      <n-descriptions label-placement="top" :title="t('commons.labels.description', 1)">
        <n-descriptions-item content-style="text-align: left">
          {{ model.description }}
        </n-descriptions-item>
      </n-descriptions>
      <Questions v-if="model.id && !isPreview" :publication="model" />
    </n-gi>
  </n-grid>
  <publication-footer-mobile
    v-if="isMobile && !isPreview"
    :buttonAction="isMyPublication ? onBack : goToRent"
    :buttonText="isMyPublication ? t('commons.actions.back') : t('commons.actions.rent')"
    :price="floatToCurrency(model.prices?.pricePerDay, t('commons.labels.day', 1))"
  />
</template>
<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import responsive from "@/mixins/responsive";
import {
  NGrid,
  NGi,
  NCarousel,
  NButton,
  NDescriptions,
  NDescriptionsItem,
} from "naive-ui";
import PublicationResumen from "@/components/publication/PublicationResumen.vue";
import PublicationFooterMobile from "@/components/publication/FooterMobile.vue";
import { floatToCurrency } from "@/shared/utils";
import Questions from "@/components/publication/questions/Main.vue";
import AboutUser from "@/components/AboutUser.vue";
import { themeOverrides } from "@/shared/constants";
import { useI18n } from "vue-i18n";

export default {
  mixins: [responsive],
  name: "ViewPublication",
  props: {
    publicationId: { type: String, required: true },
    publication: { type: Object, required: false, default: null },
    isPreview: { type: Boolean, required: false, default: false },
  },
  components: {
    PublicationResumen,
    PublicationFooterMobile,
    NGrid,
    NGi,
    NCarousel,
    NButton,
    NDescriptions,
    NDescriptionsItem,
    Questions,
    AboutUser,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();
    const modelRef = ref({
      checkListFields: [],
      category: "",
      prices: {
        pricePerDay: null,
        weeklyDiscount: null,
        monthlyDiscount: null,
        discountedPricePerDayForWeeks: null,
        discountedPricePerDayForMonths: null,
      },
    });
    const listFilesRef = ref([]);

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      const selectedPublication =
        props.publication !== null
          ? props.publication
          : await store.dispatch(
              "maintainer_publication/getPublicationByIdForView",
              props.publicationId
            );
      modelRef.value = selectedPublication;
      store
        .dispatch("maintainer_publication/getImagesByPublication", selectedPublication)
        .then((result) => {
          if (result?.length > 0) {
            result.forEach(async (img) => {
              const urlImg = await store.dispatch(
                "maintainer_publication/getImagePublicationUrlByKey",
                { key: img.key, ownerId: modelRef.value.user }
              );
              listFilesRef.value.push({
                name: img.key.split("/").pop(),
                url: urlImg,
              });
            });
          }
        })
        .finally(() => {
          store.dispatch("app/unlockUI");
        });
    });

    return {
      t,
      themeOverrides,
      model: modelRef,
      listFiles: listFilesRef,
      floatToCurrency,
      onClose() {
        return true;
      },
      onUpdate() {
        return true;
      },
      onBack() {
        router.go(-1);
      },
      goToRent() {
        router.push({ name: "rent", params: { publicationId: props.publicationId } });
      },
      isMyPublication: computed(() => {
        return modelRef.value.user === store.getters["auth/user_id"];
      }),
    };
  },
};
</script>

<style scoped>
.carousel-img {
  width: 100%;
  height: 240px;
  object-fit: contain;
}
.view-publications .n-page-header {
  @extend .mt-3;
  float: left;
}

:deep(.n-carousel .n-carousel__arrow) {
  color: v-bind(themeOverrides.common.grayDarkColor);
}
</style>

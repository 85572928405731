<template>
  <div>
    <n-form-item
      :label="t('publications.createUpdate.steps.two.form.files.label')"
      path="orderPhotos"
    >
      <n-space vertical>
        <n-upload
          :default-upload="false"
          v-model:file-list="listFiles"
          list-type="text"
          multiple
          :show-file-list="false"
          :show-retry-button="false"
          @remove="onRemoveFile"
          style="text-align: left"
          @before-upload="beforeUpload"
        >
          <n-button round size="large">
            <template #icon>
              <n-icon>
                <add />
              </n-icon>
            </template>
            {{ t("commons.actions.add", { referece: t("commons.labels.photo", 1) }) }}
          </n-button>
        </n-upload>
        <draggable :list="listFiles">
          <div
            style="display: inline-block"
            class="mt-2 mx-2"
            v-for="(element, index) in listFiles"
            :key="element.name"
          >
            <n-space vertical>
              <n-badge :value="showProfileBadge(index)" type="info">
                <n-image
                  :src="element.url"
                  height="100"
                  :fallback-src="getDataUrl(element)"
                />
              </n-badge>
              <n-text class="descImgWrap">
                {{ element.name }}
              </n-text>
              <n-button circle size="large" @click="onRemoveFile(element)">
                <template #icon>
                  <n-icon>
                    <close />
                  </n-icon>
                </template>
              </n-button>
            </n-space>
          </div>
        </draggable>
      </n-space>
    </n-form-item>
  </div>
</template>
<script>
import {
  NFormItem,
  NSpace,
  NUpload,
  NButton,
  NIcon,
  NBadge,
  NImage,
  NText,
  useMessage,
} from "naive-ui";
import { VueDraggableNext } from "vue-draggable-next";
import { Add, Close } from "@vicons/carbon";
import { onMounted, ref, computed, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
import FileReader from "@tanker/file-reader";
import { Storage } from "aws-amplify";
import { useI18n } from "vue-i18n";

export default {
  components: {
    NFormItem,
    NSpace,
    NUpload,
    NButton,
    NIcon,
    NBadge,
    NImage,
    NText,
    draggable: VueDraggableNext,
    Add,
    Close,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const message = useMessage();
    const modelRef = ref(props.modelValue);
    const deletedFiles = ref([]);
    const listFilesRef = ref([]);
    const orderPhotos = computed(() => listFilesRef.value.map((x) => x.name));

    onMounted(() => {
      store
        .dispatch("maintainer_publication/getImagesByPublication", modelRef.value)
        .then((result) => {
          if (result?.length > 0) {
            result.forEach(async (img) => {
              const urlImg = await store.dispatch(
                "maintainer_publication/getImagePublicationUrlByKey",
                { key: img.key, ownerId: modelRef.value.user }
              );
              listFilesRef.value.push({
                id: img.key,
                name: img.key.split("/").pop(),
                status: "finished",
                url: urlImg,
              });
            });
          }
        });
    });

    watch(modelRef, (newModelRef) =>
      emit("update:modelValue", { ...newModelRef, orderPhotos: orderPhotos.value })
    );
    watch(orderPhotos, (newOrderPhotos) =>
      emit("update:modelValue", { ...modelRef.value, orderPhotos: newOrderPhotos })
    );

    onBeforeUnmount(() => {
      saveFiles();
      deleteFiles();
    });

    const saveFiles = () => {
      listFilesRef.value
        ?.filter((item) => item.status == "pending")
        .forEach(async (element) => {
          const file = element.file;
          element.status = t("commons.actions.uploading");
          await Storage.put(`publication/${modelRef.value.id}/${file.name}`, file, {
            resumable: true,
            level: "protected",
            identityId: modelRef.value.user,
            contentType: file.type,
            completeCallback: () => {
              element.percentage = 100;
            },
            progressCallback: (progress) => {
              element.percentage = (progress.loaded / progress.total) * 100;
            },
            errorCallback: () => {
              element.status = "error";
            },
          });
        });
    };

    const deleteFiles = () => {
      store.dispatch("maintainer_publication/deletePublicationImages", {
        deletedFiles: deletedFiles.value,
        user: modelRef.value.user,
      });
    };

    const onRemoveFile = (file) => {
      listFilesRef.value = listFilesRef.value.filter((x) => x.name !== file.name);
      deletedFiles.value.push(file);
      return true;
    };

    const beforeUpload = async ({ file }) => {
      const reader = new FileReader(file.file);
      const blob = await reader.readAsArrayBuffer(20);
      await store.dispatch("app/lockUI");
      const result = await store.dispatch("maintainer_publication/isFileAllowed", {
        blob,
        file,
      });
      await store.dispatch("app/unlockUI");
      if (!result.allowed) {
        message.error(result.message);
      }
      return result.allowed;
    };

    const showProfileBadge = (value) => (value === 0 ? t("commons.labels.main") : value);

    const getDataUrl = (element) => {
      if (element.url === null && element.file && element.status === "pending") {
        const reader = new FileReader(element.file);
        reader.readAsDataURL().then((result) => {
          element.url = result;
        });
      }
      return process.env.BASE_URL + "img/tool-box.png";
    };

    return {
      t,
      onRemoveFile,
      beforeUpload,
      showProfileBadge,
      getDataUrl,
      listFiles: listFilesRef,
    };
  },
};
</script>
<style scoped>
.descImgWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  display: block;
}
</style>

<template>
  <n-grid :cols="4" class="footer-mobile" :class="isiOSPlatform ? 'footer-ios' : ''">
    <n-gi span="3">
      <div class="pt-4 pb-2 pl-4 pr-1">{{ price }}</div>
    </n-gi>
    <n-gi>
      <n-button type="primary" size="large" class="my-3" @click="buttonAction">{{
        buttonText
      }}</n-button>
    </n-gi>
  </n-grid>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { NGrid, NGi, NButton } from "naive-ui";

export default defineComponent({
  name: "PublicationFooterMobile",
  props: {
    buttonAction: { type: Function, required: true },
    buttonText: { type: String, required: true },
    price: { type: String, required: true },
  },
  components: { NGrid, NGi, NButton },
  setup() {
    const store = useStore();
    const isiOSPlatform = computed(() => store.state.app.isiOS);
    return {
      isiOSPlatform,
    };
  },
});
</script>

<style lang="scss" scoped>
.footer-mobile {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  background: white;
}
.footer-ios {
  @mixin supports-safe-area-insets {
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      @content;
    }
  }
  @include supports-safe-area-insets {
    height: calc(env(safe-area-inset-bottom) + 60px);
  }
}
</style>
